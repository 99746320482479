export default {
  kaporal_fr_de: [
    {
      title: "APIs",
      description:
        "APIs werden benutzt um Skripte zu laden, wie: Geolokalisierung, Suchmaschinen, Übersetzungen, ...",
      services: [
        {
          name: "abtasty",
          title: "ABTasty",
          cookies: [
            "ABTasty",
            "ABTastySession",
          ],
          description:
            "",
          link: "https://www.abtasty.com/terms-of-use/",
        },
      ],
    },
    {
      title: "Marketing",
      description:
        "Werbenetzwerke können mit dem Verkauf von Werbeplatzierungen auf der Seite Einnahmen erhalten.",
      services: [
        {
          name: "bing",
          title: "Bing",
          cookies: [],
          description:
            "Analytics cookies, from the Google third party, are intended to collect statistics of anonymous visits which, once cross-checked, could be intrusive for the privacy of users of the site. As a result, the use of Google Analytics tracers is disabled by default on this site. You can authorize them to allow the person in charge of the site to improve the quality of the navigation and the information provided on this site.",
          link: "",
        },
        {
          name: "criteostatic",
          title: "Criteo",
          cookies: [],
          description: "",
          link: "",
        },
        {
          name: "facebookpixel",
          title: "Facebook Pixel",
          cookies: ['datr', 'fr', 'reg_ext_ref', 'reg_fb_gate', 'reg_fb_ref', 'sb', 'wd', 'x-src'],
          link: "https://fr-fr.facebook.com/business/help/www/651294705016616",
        },
        {
          name: "googleads",
          title: "Google Ads",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "numberly",
          title: "Numberly",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "powerspace",
          title: "Powerspace",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "taboola",
          title: "Taboola",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "tradedoubler",
          title: "Tradedoubler",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "neads",
          title: "Neads",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "adsteroid",
          title: "Adsteroid",
          cookies: [],
          description : "",
          link : "",
        },
      ],
    },
    {
      title: "Leistung & Analyse Web",
      description: "Die verwendeten Besucher Zähldienste generieren Statistiken die dabei helfen, die Seite zu verbessern.",
      services: [
        {
          name: "actito",
          title: "Actito",
          cookies: [],
          description : "",
          link : "",
        },
      ]
    },
    {
      title: "Anpassung",
      description: "Dienste zum Anzeigen von Web-Inhalten.",
      services: [
        {
          name: "batch",
          title: "Batch",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "heyday",
          title: "Heyday",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "olapic",
          title: "Olapic",
          cookies: ['__olapicU'],
          description : "",
          link : "",
        },
        {
          name: "onestock",
          title: "Onestock",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "contentsquare",
          title: "Contentsquare",
          cookies: [],
          description : "",
          link : "",
        },
      ]
    },
    
  ],
  kaporal_fr_en: [
    {
      title: "APIs",
      description:
        "APIs are used to load scripts: geolocation, search engines, translations, ...",
      services: [
        {
          name: "abtasty",
          title: "ABTasty",
          cookies: [
            "ABTasty",
            "ABTastySession",
          ],
          description:
            "",
          link: "https://www.abtasty.com/terms-of-use/",
        },
      ],
    },
    {
      title: "Marketing",
      description:
        "Ad networks can generate revenue by selling advertising space on the site.",
      services: [
        {
          name: "bing",
          title: "Bing",
          cookies: [],
          description:
            "Analytics cookies, from the Google third party, are intended to collect statistics of anonymous visits which, once cross-checked, could be intrusive for the privacy of users of the site. As a result, the use of Google Analytics tracers is disabled by default on this site. You can authorize them to allow the person in charge of the site to improve the quality of the navigation and the information provided on this site.",
          link: "",
        },
        {
          name: "criteostatic",
          title: "Criteo",
          cookies: [],
          description: "",
          link: "",
        },
        {
          name: "facebookpixel",
          title: "Facebook Pixel",
          cookies: ['datr', 'fr', 'reg_ext_ref', 'reg_fb_gate', 'reg_fb_ref', 'sb', 'wd', 'x-src'],
          link: "https://fr-fr.facebook.com/business/help/www/651294705016616",
        },
        {
          name: "googleads",
          title: "Google Ads",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "numberly",
          title: "Numberly",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "powerspace",
          title: "Powerspace",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "neads",
          title: "Neads",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "taboola",
          title: "Taboola",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "tradedoubler",
          title: "Tradedoubler",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "adsteroid",
          title: "Adsteroid",
          cookies: [],
          description : "",
          link : "",
        },
      ],
    },
    {
      title: "Performance & web analysis",
      description: "The audience measurement services used to generate useful statistics attendance to improve the site.",
      services: [
        {
          name: "actito",
          title: "Actito",
          cookies: [],
          description : "",
          link : "",
        },
      ]
    },
    {
      title: "Customization",
      description: "Services to display web content.",
      services: [
        {
          name: "batch",
          title: "Batch",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "heyday",
          title: "Heyday",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "olapic",
          title: "Olapic",
          cookies: ['__olapicU'],
          description : "",
          link : "",
        },
        {
          name: "onestock",
          title: "Onestock",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "contentsquare",
          title: "Contentsquare",
          cookies: [],
          description : "",
          link : "",
        },
      ]
    },
  ],
  kaporal_fr_es: [
    {
      title: "APIs",
      description:
        "APIs se utilizan para cargar scripts: geolocalización, motor de búsqueda, traducciones, ...",
      services: [
        {
          name: "abtasty",
          title: "ABTasty",
          cookies: [
            "ABTasty",
            "ABTastySession",
          ],
          description:
            "",
          link: "https://www.abtasty.com/terms-of-use/",
        },
      ],
    },
    {
      title: "Marketing",
      description:
        "Las redes publicitarias pueden generar ingresos mediante la venta de espacios publicitarios en el sitio.",
      services: [
        {
          name: "bing",
          title: "Bing",
          cookies: [],
          description: "",
          link: "",
        },
        {
          name: "criteostatic",
          title: "Criteo",
          cookies: [],
          description: "",
          link: "",
        },
        {
          name: "facebookpixel",
          title: "Facebook Pixel",
          cookies: ['datr', 'fr', 'reg_ext_ref', 'reg_fb_gate', 'reg_fb_ref', 'sb', 'wd', 'x-src'],
          link: "https://fr-fr.facebook.com/business/help/www/651294705016616",
        },
        {
          name: "googleads",
          title: "Google Ads",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "numberly",
          title: "Numberly",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "powerspace",
          title: "Powerspace",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "taboola",
          title: "Taboola",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "tradedoubler",
          title: "Tradedoubler",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "neads",
          title: "Neads",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "adsteroid",
          title: "Adsteroid",
          cookies: [],
          description : "",
          link : "",
        },
      ],
    },
    {
      title: "Rendimiento y análisis web",
      description: "Los servicios de medición de audiencia se usan para generar estadísticas útiles para mejorar el sitio.",
      services: [
        {
          name: "actito",
          title: "Actito",
          cookies: [],
          description : "",
          link : "",
        },
      ]
    },
    {
      title: "Personalización",
      description: "Servicios para mostrar contenido web.",
      services: [
        {
          name: "batch",
          title: "Batch",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "heyday",
          title: "Heyday",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "olapic",
          title: "Olapic",
          cookies: ['__olapicU'],
          description : "",
          link : "",
        },
        {
          name: "onestock",
          title: "Onestock",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "contentsquare",
          title: "Contentsquare",
          cookies: [],
          description : "",
          link : "",
        },
      ]
    },
  ],
  kaporal_fr_fr: [
    {
      title: "APIs",
      description:
        "Les APIs permettent de charger des scripts : géolocalisation, moteurs de recherche, traductions, ...",
      services: [
        {
          name: "abtasty",
          title: "ABTasty",
          cookies: [
            "ABTasty",
            "ABTastySession",
          ],
          description:
            "",
          link: "https://www.abtasty.com/terms-of-use/",
        },
      ],
    },
    {
      title: "Marketing",
      description:
        "Les régies publicitaires permettent de générer des revenus en commercialisant les espaces publicitaires du site.",
      services: [
        {
          name: "bing",
          title: "Bing",
          cookies: [],
          description: "",
          link: "",
        },
        {
          name: "criteostatic",
          title: "Criteo",
          cookies: [],
          description: "",
          link: "",
        },
        {
          name: "facebookpixel",
          title: "Facebook Pixel",
          cookies: ['datr', 'fr', 'reg_ext_ref', 'reg_fb_gate', 'reg_fb_ref', 'sb', 'wd', 'x-src'],
          link: "https://fr-fr.facebook.com/business/help/www/651294705016616",
        },
        {
          name: "googleads",
          title: "Google Ads",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "numberly",
          title: "Numberly",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "powerspace",
          title: "Powerspace",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "taboola",
          title: "Taboola",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "tradedoubler",
          title: "Tradedoubler",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "neads",
          title: "Neads",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "adsteroid",
          title: "Adsteroid",
          cookies: [],
          description : "",
          link : "",
        },
      ],
    },
    {
      title: "Performance & analyse web",
      description: "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles à l'amélioration du site.",
      services: [
        {
          name: "actito",
          title: "Actito",
          cookies: [],
          description : "",
          link : "",
        },
      ]
    },
    {
      title: "Personnalisation",
      description: "Services visant à afficher du contenu web.",
      services: [
        {
          name: "batch",
          title: "Batch",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "heyday",
          title: "Heyday",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "olapic",
          title: "Olapic",
          cookies: ['__olapicU'],
          description : "",
          link : "",
        },
        {
          name: "onestock",
          title: "Onestock",
          cookies: [],
          description : "",
          link : "",
        },
        {
          name: "contentsquare",
          title: "Contentsquare",
          cookies: [],
          description : "",
          link : "",
        },
      ]
    },
  ],
};
